<template>
  <div class="px-40 border border-EEEEEE py-35 purseBox">
    <!-- 标签 -->
    <div class="flex align-center justify-between pb-20 border-bottom border-F4F4F4">
      <div class="font-20 text-333333">我的推广</div>
      <div class="font-12 text-666666 flex align-center">
        目前所在：<el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/myIndex' }">个人中心</el-breadcrumb-item>
          <el-breadcrumb-item>我的推广</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!-- 内容 -->
    <div>
      <!-- 搜索 -->
      <div class="py-15 purseBox_search">
        <!-- 搜索框 -->
        <div class="flex a-center cursorp">
          <el-input v-model="keyword" placeholder="请输入用户ID/昵称">
            <div slot="prefix" class="text-555555">
              <i class="el-icon-search"></i>
            </div>
            <div
              class="flex a-center search justify-center"
              slot="append"
              @click="getdata()"
            >
              搜索
            </div>
          </el-input>
        </div>
      </div>
      <!-- 上级 -->
      <div class="py-15 font-weight-bolder">我的上级</div>
      <div class="bg-white flex align-center justify-between font-14">
        <div class="flex align-center">
          <div class="circle mr-15">
            <!-- <img
              :src="parent_avatar"
              alt=""
              class="circle"
              width="1.875rem"
              height="1.875rem"
            /> -->
            <el-image
              class="circle"
              style="width: 1.875rem; height: 1.875rem"
              :src="parent_avatar"
              fit="cover"
            ></el-image>
          </div>
          <div>
            <!-- parent_remark ml-10 -->
            <div v-if="parent_remark" class="cursorp" @click="openremark(1)">
              {{ parent_remark }}
            </div>
            <div v-else class="flex align-center">
              {{ parent_name }}
              <div class="remark ml-10" v-if="!parent_remark" @click="openremark(1)">
                <div class="beizhu">
                  <!-- <img src="@/assets/img/beizhu.png" class="beizhu" alt="" /> -->
                  <el-image
                    class="beizhu"
                    :src="require('@/assets/img/beizhu.png')"
                    fit="cover"
                  ></el-image>
                </div>
                备注
              </div>
            </div>
          </div>
        </div>
        <div class="text-B5B5B5">用户ID:{{ parent_id }}</div>
      </div>
      <!-- 标题 -->
      <div
        class="pt-25 pb-20 border-bottom border-EEEEEE flex align-center justify-between"
      >
        <div class="font-weight-bolder">分享用户：{{ child_one_nums3 }}</div>
        <div class="flex align-center">
          <div
            class="flex align-center font-14 text-000033 mr-50 purseBox_num cursorp"
            @click="cutsort"
          >
            下级数量
            <div class="ml-10 font-16 text-CCCCCC">
              <div
                class="purseBox_num_top"
                :class="child_order == 2 ? 'text-E7524C' : ''"
              >
                <i class="el-icon-caret-top"></i>
              </div>
              <div
                class="purseBox_num_bottom"
                :class="child_order == 1 ? 'text-E7524C' : ''"
              >
                <i class="el-icon-caret-bottom"></i>
              </div>
            </div>
          </div>
          <div class="mr-45 font-12">分享用户：{{ child_one_nums3 }}人</div>
          <div class="mr-45 font-12">分享vip会员数：{{ child_one_nums4 }}人</div>
          <div class="mr-45 font-12">团队会员数：{{ child_one_nums2 }}人</div>
          <div class="font-12">团队VIP会员总数：{{ child_one_nums }}人</div>
        </div>
      </div>
      <!--内容  -->
      <div>
        <div
          class="py-20 border-bottom border-EEEEEE flex align-center justify-between"
          v-for="(item, index) in info"
          :key="index"
        >
          <div class="flex">
            <!-- <img
              :src="item.avatar"
              width="3.3125rem"
              height="3.3125rem"
              class="circle mr-20"
              alt=""
            /> -->
            <el-image
              class="circle mr-20"
              style="width: 3.3125rem; height: 3.3125rem"
              :src="item.avatar"
              fit="cover"
            ></el-image>
            <div class="font-14">
              <div class="mb-10">
                <div class="cursorp" v-if="item.remark" @click="openremark(2, item)">
                  {{ item.remark }}
                </div>
                <!-- <div
                  v-if="item.remark"
                  class="parent_remark ml-10"
                  @click="openremark(2, item)"
                >
                  查看备注
                  {{ item.remark }}
                </div> -->
                <div v-else class="flex align-center">
                  {{ item.username }}
                  <div class="remark ml-10" @click="openremark(2, item)">
                    <div class="beizhu">
                      <img src="@/assets/img/beizhu.png" class="beizhu" alt="" />
                    </div>
                    备注
                  </div>
                </div>
              </div>
              <div class="mb-12">
                <span class="cursorp" @click="goxiaofeiqingdan(item.user_id)"
                  >消费清单<i class="el-icon-arrow-right"></i
                ></span>
                <!-- 上级 <span class="text-222222">{{ item.parent_name }}</span> -->
              </div>
              <div class="text-B5B5B5">
                VIP时间：
                <span v-if="item.vip_start_time">{{ item.vip_start_time }}</span
                ><span v-if="item.vip_start_time && item.vip_end_time">~</span
                ><span v-if="item.vip_end_time">{{ item.vip_end_time }}</span>
              </div>
            </div>
          </div>
          <div>
            <div class="mb-35 font-14 text-999999">用户ID{{ item.id }}</div>
            <div class="text-666666 font-14">
              <span class="mr-20 text-E7524C">下级：{{ item.child_one_nums }}人</span>
              <span class="cursorp" @click="gosubordinate(item.user_id)"
                >查看下级<i class="el-icon-arrow-right"></i
              ></span>
            </div>
          </div>
        </div>
      </div>
      <!--备注 -->
      <el-dialog :visible.sync="dialogVisible" width="30%" center :show-close="false">
        <div>
          <!-- 头部 -->
          <div class="flex align-center justify-between mb-10">
            <div class="flex align-center">
              <div class="mr-15 remarkavatar">
                <img :src="remarkavatar" alt="" class="remarkavatar" />
              </div>
              {{ remarkName }}
            </div>
            <div
              class="font-20 font-weight-bolder cursorp"
              @click="dialogVisible = false"
            >
              <i class="el-icon-close"></i>
            </div>
          </div>
          <!-- 内容区域 -->
          <div class="mb-30">
            <el-input type="textarea" placeholder="输入对TA的备注" v-model="remark">
            </el-input>
          </div>
          <!-- 按钮 -->
          <div class="remarkbtn cursorp" @click="sbmitremark">提交</div>
        </div>
      </el-dialog>
      <!-- 分页 -->
      <div class="flex align-center justify-center py-50 border-top border-ligit-BFBFBF">
        <el-pagination
          background
          layout="prev, pager, next"
          prev-text="上一页"
          next-text="下一页"
          :page-count="page_nums"
          @current-change="currentChange"
          :current-page="page"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import req from "../../../utils/req";
export default {
  data() {
    return {
      keyword: "",
      info: [],
      page: 1,
      page_nums: 0,
      team_nums: 0,
      child_one_nums: 0,
      parent_avatar: "",
      parent_name: "",
      parent_id: 0,
      child_one_nums4:0,
      child_one_nums3: 0,
      child_one_nums2: 0,
      child_order: 1, //1-正序 2-降序
      parent_remark: "",
      parent_user_id: "",
      dialogVisible: false,
      remark: "",
      remarkName: "",
      remarkId: "",
      remarkavatar: "",
    };
  },
  mounted() {
    this.getdata();
  },
  methods: {
    sbmitremark() {
      let that = this;
      req
        .post("center/userRemark", {
          remark_id: that.remarkId,
          remark: that.remark,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          that.$message.success(res.msg);
          that.dialogVisible = false;
          that.getdata();
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
    openremark(type, item) {
      if (type == 1) {
        this.remark = this.parent_remark;
        this.remarkName = this.parent_name;
        this.remarkId = this.parent_user_id;
        this.remarkavatar = this.parent_avatar;
      } else {
        this.remark = item.remark;
        this.remarkName = item.parent_name;
        this.remarkId = item.user_id;
        this.remarkavatar = item.avatar;
      }
      this.dialogVisible = true;
    },
    // 消费清单
    goxiaofeiqingdan(id) {
      this.$router.push({
        path: "xiaofeiqindan",
        query: { type: "xiaofeiqindan",subordinateid: id },
      });
    },
    // 查看下级
    gosubordinate(id) {
      this.$router.push({
        path: "subordinate",
        query: { type: "subordinate",subordinateid: id },
      });
    },
    cutsort() {
      if (this.child_order == 1) {
        this.child_order = 2;
      } else {
        this.child_order = 1;
      }
      this.getdata();
    },
    currentChange(e) {
      console.log(e);
      this.page = e;
      this.getdata();
    },
    // 获取数据
    getdata() {
      let that = this;
      req
        .post("center/mySale", {
          _keywords: that.keyword,
          page: that.page,
          child_order: that.child_order,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          that.child_one_nums = res.data.child_one_nums;
          that.team_nums = res.data.team_nums;
          that.parent_name = res.data.parent_name;
          that.parent_remark = res.data.parent_remark;
          that.parent_id = res.data.parent_id;
          that.parent_avatar = res.data.parent_avatar;
          that.parent_user_id = res.data.parent_user_id;
          that.child_one_nums3 = res.data.child_one_nums3;
          that.child_one_nums4 = res.data.child_one_nums4;
          that.child_one_nums2 = res.data.child_one_nums2;
          that.info = res.data.child_one_list.data;
          that.page_nums = res.data.child_one_list.last_page;
        })
        .catch((err) => {
          console.log(err);
          // that.$message.error(err.msg);
        });
    },
  },
};
</script>
<style>
.el-dialog--center .el-dialog__body {
  padding: 1.5625rem;
}
.el-dialog {
  border-radius: 0.625rem;
  width: 31.25rem;
  height: 27.4375rem;
}
.el-dialog__header {
  padding: 0;
}
.el-textarea__inner {
  height: 12.5rem !important;
  resize: none !important;
}
</style>
<style lang="scss" scoped>
.purseBox {
  height: 100%;
  box-sizing: border-box;
  &_num {
    position: relative;
    &_top {
      position: absolute;
      top: -8%;
      right: -8%;
    }
    &_bottom {
      position: absolute;
      bottom: -20%;
      right: -8%;
    }
  }
  &_search {
    background: #eeeeee;
    display: flex;
    align-items: center;
    justify-content: center;
    ::v-deep .el-input-group {
      width: auto;
    }
    ::v-deep .el-input__prefix {
      top: 0.4375rem;
    }
    ::v-deep .el-input__inner {
      -webkit-appearance: none;
      background-color: #ffffff;
      // border-radius: 6.25rem;
      border-top-left-radius: 6.25rem;
      border-bottom-left-radius: 6.25rem;
      border: 0;
      box-sizing: border-box;
      color: #222222;
      display: inline-block;
      font-size: 0.875rem;
      height: 1.875rem;
      line-height: 1.875rem;
      outline: 0;
      padding: 0 1.5625rem;
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      width: 20.5625rem;
    }
    .search {
      width: 4.9375rem;
      height: 1.875rem;
      line-height: 1.875rem;
      background-color: #c9372e;
      color: #fff;
      border-radius: 6.25rem;
      margin-left: -1.125rem;
      cursor: pointer;
    }

    ::v-deep .el-input-group__append,
    .el-input-group__prepend {
      background-color: #c9372e;
      color: #fff;
      vertical-align: middle;
      display: table-cell;
      border: 0;
      border-radius: 6.25rem;
      padding: 0;
      white-space: nowrap;
    }
  }
}
.parent_remark {
  padding: 0.375rem 0.5rem;
  color: #356885;
  font-size: 0.875rem;
  border-radius: 0.125rem;
  background-color: #e1eff7;
  cursor: pointer;
}
.remark {
  cursor: pointer;
  padding: 0.25rem 0.375rem;
  background-color: #ffeded;
  border-radius: 0.125rem;
  font-size: 0.875rem;
  color: #ff6f62;
  display: flex;
  align-items: center;
  .beizhu {
    width: 0.9375rem;
    height: 1.0313rem;
    margin-right: 0.3125rem;
  }
}
.remarkavatar {
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 50%;
}
.remarkbtn {
  height: 3.75rem;
  line-height: 3.75rem;
  text-align: center;
  color: #ffffff;
  border-radius: 1.875rem;
  width: 100%;
  background-color: #ff524c;
}
</style>
